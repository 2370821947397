jQuery(document).ready(function ($) {

  // Slides
  var swiper = new Swiper('.js-slider-collections', {
    slidesPerView: 'auto',
    centeredSlides: true,
    paginationClickable: true,
    autoplay: {
      delay: 8000,
    },
    pagination: {
      el: '.js-slider-collections .swiper-pagination',
      type: 'bullets',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
      },
    }
  });

  $('.js-slider-products').each(function(index, el) {
    var swiper = new Swiper(el, {
      slidesPerView: 'auto',
      centeredSlides: true,
      paginationClickable: true,
      pagination: {
        el: $(el).find('.swiper-pagination'),
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: $(el).find(".swiper-button-next"),
        prevEl: $(el).find(".swiper-button-prev"),
      }
    });
  });

  // Header
  $(window).on('scroll', function() {
    const $html = $('html'),
          $this = $(this);
    
    if ($this.scrollTop() != 0) {
      $html.addClass('is-active-header')
    } else {
      $html.removeClass('is-active-header')
    }
  });

  // Remove action
  $('.js-no-action > a').on('click', function(e) {
    e.preventDefault();
  });

  // Menu mobile
  $('.js-menu-mobile').on('click', function(e) {
    const $html = $('html');

    $html.toggleClass('is-active-menu');
  });

  // Play video yt
  $('.js-video-player').on('click', function() {
    const $video = $(this),
          videoSrc = $video.data('video'),
          videoPlayer = $video.children('.js-video-play');
    
    $video.addClass('is-playing');
    videoPlayer.attr('src', videoSrc);
  });

  // Goto
  $('.js-goto').on('click', function(e) {
    e.preventDefault();
    const id = $(this).attr('href');
    $('html').animate({scrollTop: $(id).offset().top}, 'slow')
  });

  // Animeted on scroll
  $(window).on('scroll', function() {
    const winHeight = $(this).innerHeight(),
          winScroll = $(this).scrollTop() + winHeight;

    $('.js-animated').each(function() {
      const $this = $(this),
            elTop = $this.offset().top;

      if(winScroll >= elTop + winHeight / 3) {
        $this.addClass('is-visible');
      }
    });
  });

  // Animated start
  function start() {
    $('.js-animated-start').addClass('is-visible');
  }

  start();

  // MASKED INPUT
  $(".js-data").mask("99/99/9999");
  $(".js-cpf").mask("999.999.999-99");
  $(".js-cep").mask("99999-999");
  $(".js-cnpj").mask("99.999.999/9999-99");
  $('.js-phone').focusout(function(){
    var phone, element;
    element = $(this);
    element.unmask();
    phone = element.val().replace(/\D/g, '');
    if(phone.length > 10) {
      element.mask("(99) 99999-999?9");
    } else {
      element.mask("(99) 9999-9999?9");
    }
  }).trigger('focusout');

});